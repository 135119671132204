import { useContext, useEffect, useState } from "react";
import { PropsContext } from "../../App";
import { Link, redirectDocument, useParams } from "react-router-dom";
import { FaRegTrashAlt as Trash, FaArrowCircleLeft as BackIcon } from "react-icons/fa";
import "./singleListItemView.css";

type listResponse = {
	item: string;
	id: string;
	listName: string;
};

const SingleListItemViev = () => {
	const { setLoading, setRefresh, refresh } = useContext(PropsContext);

	const [inputData, setInputData] = useState("");
	const [userList, setUserList] = useState<listResponse[] | []>([]);
	const [showError, setShowError] = useState(false);
	const [reload, setReload] = useState(false);

	const { listId } = useParams();

	useEffect(() => {
		const load = async () => {
			setLoading(true);
			setShowError(false);
			try {
				const res = await fetch(`/singleListItem/${listId}`, { redirect: "follow" });

				try {
					const resJson = await res.json();
					setUserList(resJson);
				} catch (error) {}
			} catch (error) {
				console.log(error);
				redirectDocument("/");
			} finally {
				setLoading(false);
				setRefresh(!refresh);
			}
		};
		load();
		//pobieranie wpisów
	}, [reload]);

	const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		setShowError(false);
		try {
			const newData = await fetch(`/singleListItem/${listId}`, {
				method: "put",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ item: inputData }),
			});
			if (newData.status !== 202) {
				setShowError(true);
			} else {
				setInputData("");
				setShowError(false);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setRefresh(!refresh);
			setReload(!reload);
		}
	};
	const deleteSingleItem = async (id: string) => {
		try {
			await fetch(`/singleListItem/${id}`, {
				method: "delete",
			});
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setRefresh(!refresh);
			setReload(!reload);
		}
	};

	return (
		<>
			<Link to={"/"}>
				<div
					className="divBackIcon"
					// onClick={() => {
					// 	window.location.pathname = "/";
					// }}
				>
					<BackIcon className="backIcon" />
					WSTECZ
				</div>
			</Link>

			<div className="containerSingleListItemView">
				<form action="" onSubmit={(e) => submitHandler(e)} className="formSingleListItemView">
					<label htmlFor="newItem">Dodaj nową pozycjedo listy {userList[0]?.listName}</label>
					<input type="text" name="newItem" id="newItem" placeholder="wpisz ..." value={inputData} onChange={(e) => setInputData(e.target.value)} />
					<button type="submit"> Zapisz</button>
					{showError && <h5>Wpis już istnieje {inputData}</h5>}
				</form>
			</div>
			<div className="line"></div>
			<div className="allListItems">
				{userList.length === 0 && <span>- - - Brak wpisów - - -</span>}
				{userList?.map((e, index) => {
					return (
						<>
							<div className="singleItem" key={index}>
								<p>{e.item}</p>
								<Trash className="trashSingleItem" onClick={() => deleteSingleItem(e.id)} />
							</div>
						</>
					);
				})}
			</div>
		</>
	);
};

export default SingleListItemViev;
