import "./stopka.css";
import logo from "../assets/image/adibauShopingList_600x600.png";
import { CiLogout as IconLogOut } from "react-icons/ci";
type Props = {
	userEmail?: string;
};
function Stopka(props: Props) {
	const logOut = async () => {
		await fetch("/logOut", { redirect: "follow" });
		document.location.href = "/";
	};
	return (
		<>
			<section className="stopka">
				<div className="line"></div>
				<div className="stopkaDiv">
					<img srcSet={logo} alt="logo" className="stopkaLogo" />
					<p>Zalogowany na konto : {props.userEmail}</p>
					<div className="logOut" onClick={logOut}>
						<p>Wyloguj</p>
						<IconLogOut />
					</div>
				</div>
			</section>
		</>
	);
}

export default Stopka;
