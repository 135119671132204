import React, { useState, useRef, useContext } from "react";
import "./LoginRegister.css";

import logo from "../assets/image/adibauShopingList_600x600.png";
import { PropsContext } from "../App";

type Data = {
	Pin: number | "";
	email: string;
	email2: string;
};
type ResponseData = {
	msg: string;
	status: number;
	token: string;
};

export const LoginRegister = () => {
	const [login, setLogin] = useState<boolean>(true);
	const [data, setData] = useState<Data>({ Pin: "", email: "", email2: "" });
	const [wynik, setWynik] = useState<Omit<ResponseData, "token"> | null>(null);

	const { setLoading, setRefresh, refresh } = useContext(PropsContext);

	const refPin = useRef<HTMLInputElement | null>(null);
	const refPinError = useRef<HTMLDivElement | null>(null);
	const refWynik = useRef<HTMLDivElement | null>(null);

	const clearData = () => {
		setData({ Pin: "", email: "", email2: "" });
		setWynik(null);
	};

	const LoginRejestracja = (name: string) => {
		if (name === "login") setLogin(true);
		if (name === "rejestracja") setLogin(false);
		clearData();
	};

	const dataHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const wysliLogin = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (data.Pin.toString().length < 4) {
			return;
		}

		try {
			const login = await fetch("/login", {
				method: "post",
				redirect: "follow",
				credentials: "include",
				body: JSON.stringify({ Pin: data.Pin, email: data.email }),
				headers: { "Content-Type": "application/json" },
			});
			const res = (await login.json()) as ResponseData;
			if (res.status === 202) {
				setRefresh(!refresh);
				setTimeout(() => clearData(), 1500);
			}
			setWynik({ msg: res.msg, status: res.status });
		} catch (error) {
			setWynik({ msg: "Ups... try again later", status: 400 });
		} finally {
			setLoading(false);
		}
	};
	const wysliRegister = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setWynik(null);
		if (data.Pin.toString().length < 4 || data.email !== data.email2) {
			return;
		}
		setLoading(true);
		try {
			const register = await fetch("/register", {
				method: "post",
				body: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			});
			const res = (await register.json()) as ResponseData;
			setWynik({ msg: res.msg, status: res.status });
			if (res.status === 201) {
				setTimeout(() => {
					clearData();
					setLogin(true);
				}, 1500);
			}
		} catch (error) {
			setWynik({ msg: "Ups... try again later", status: 400 });
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className="container">
				<div className="logo">
					<img src={logo} alt="Logo AdiBau Shoping List" />
				</div>
				<div className="wrap-login">
					<form onSubmit={login ? (e) => wysliLogin(e) : (e) => wysliRegister(e)} className="login-form logowanie" id="main-form" autoComplete="off">
						<h1>{login ? "Zaloguj się do Shoping list" : "Nowe konto do Shoping list"}</h1>
						<div className="field" id="field-wrap">
							<input type="number" className="inputPin" name="Pin" id="Pin" required ref={refPin} value={data.Pin} onChange={(e) => dataHandler(e)} />
							<label htmlFor="Pin" className="labelP">
								PIN
							</label>
							<span className={data.Pin.toString().length < 4 && data.Pin.toString().length > 0 ? "pinErr show-err" : "pinErr"} id="under-pin" ref={refPinError}>
								Minimalna długość to 4 znaki
							</span>
						</div>
						<span className="line" id="line"></span>

						<div className="field" id="field-wrap2">
							<input type="email" name="email" id="email" required minLength={4} value={data.email} onChange={(e) => dataHandler(e)} />
							<label htmlFor="email">Email</label>
							<span className={data.email.length > 4 && !data.email.includes("@") && !data.email.includes(".") ? "underlineEmail show-err" : "underlineEmail"} id="under-Email">
								Wprowadź poprawny email
							</span>
						</div>

						<span className="line"></span>
						{!login && (
							<>
								<div className="field" id="field-wrap3">
									<input type="email" name="email2" id="email2" required minLength={4} value={data.email2} onChange={(e) => dataHandler(e)} />
									<label htmlFor="email2">Email ponownie</label>
									<span className={data.email !== data.email2 ? "underlineEmail show-err" : "underlineEmail"} id="under-email2">
										Email różni się
									</span>
								</div>

								<span className="line"></span>
							</>
						)}

						<button type="submit" className="ok loginOK" id="ok">
							OK
						</button>

						{wynik && (
							<div className={wynik ? (wynik.status < 210 ? "wynikOK visible" : "visible") : "wynik"} ref={refWynik}>
								{wynik.msg}
							</div>
						)}

						<div className="btns">
							<p className={login ? "login-btn color-white" : "login-btn color-grey"} onClick={() => LoginRejestracja("login")}>
								Login
							</p>
							<p className={!login ? "register-btn color-white" : "register-btn color-grey"} onClick={() => LoginRejestracja("rejestracja")}>
								Rejestracja
							</p>
							<div className={login ? "slide-btn slide-left color-white" : "slide-btn slide-right color-white"}></div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
