import { useEffect, useState, createContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginRegister } from "./LogReg/LoginRegister";
import { Spinner } from "./spinner/Spinner";
import { Home } from "./Pages/Home";
import SingleListItemViev from "./Pages/SingleListItemView/SingleListItemViev";
import Stopka from "./stopka/Stopka";

type autoLoginResponse = {
	msg: string;
};
type propsTypes = {
	refresh: boolean;
	setRefresh: (e: boolean) => void;
	loading: boolean;
	setLoading: (e: boolean) => void;
	user: string;
};
export const PropsContext = createContext<propsTypes>({ loading: false, refresh: false, setLoading: () => Promise, setRefresh: () => Promise, user: "" });

function App() {
	const [refresh, setRefresh] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loged, setLoged] = useState(false);
	const [user, setUser] = useState<string>("");

	const propsContextValue = {
		refresh,
		setRefresh,
		loading,
		setLoading,
		user,
	};
	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				const autoLogin = await fetch("/autoLogin", {
					method: "get",
					redirect: "follow",
					credentials: "include",
				});
				const data = (await autoLogin.json()) as autoLoginResponse;
				if (autoLogin.status === 202) {
					setLoged(true);
					setUser(data.msg);
				} else {
					setLoged(false);
				}
			} catch (error) {
				setLoged(false);
				setUser("");
			} finally {
				setLoading(false);
			}
		})();
	}, [refresh]);
	return (
		<>
			<PropsContext.Provider value={propsContextValue}>
				<BrowserRouter>
					<Routes>
						<Route path={"/"} element={<>{loged ? <Home /> : <LoginRegister />}</>} />
						<Route path={"/login"} element={<LoginRegister />} />
						<Route path={"/singleListItemView/:listId"} element={<SingleListItemViev />} />
						<Route path={"*"} element={<>{loged ? <Home /> : <LoginRegister />}</>} />
					</Routes>
				</BrowserRouter>
			</PropsContext.Provider>
			{loged && <Stopka userEmail={user} />}
			{loading && <Spinner />}
		</>
	);
}

export default App;

