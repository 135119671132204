import { useContext } from "react";
import { FaRegTrashAlt as Trash } from "react-icons/fa";
import { PropsContext } from "../../App";
import "./singleListItem.css";
import { Link } from "react-router-dom";

type propsItem = {
	name: string;
	id: string;
};
export const SingleListItem = (props: propsItem) => {
	const { id, name } = props;

	const { setLoading, setRefresh, refresh } = useContext(PropsContext);

	const deleteItem = async (id: string) => {
		setLoading(true);
		try {
			await fetch(`/addListName/${id}`, {
				method: "delete",
			});
		} catch (error) {
			//
		} finally {
			setLoading(false);
			setRefresh(!refresh);
		}
	};

	return (
		<>
			<div className="singleList">
				<Link to={`/singleListItemView/${id}`}>
					<p>{name.toLowerCase()}</p>
				</Link>
				<Trash
					className="trashIcon"
					onClick={() => {
						deleteItem(id);
					}}
				/>
			</div>
		</>
	);
};

export default SingleListItem;
