import "./Home.css";
import { useContext, useEffect, useState } from "react";
import SingleListItem from "./SingleListItem/SingleListItem";
import { PropsContext } from "../App";

type listResponse = {
	name: string;
	id: string;
};
export const Home = () => {
	const [data, setData] = useState("");
	const [userList, setUserList] = useState<listResponse[] | []>();
	const [showError, setShowError] = useState(false);

	const { setLoading, setRefresh, refresh } = useContext(PropsContext);

	useEffect(() => {
		setLoading(true);
		const getListName = async () => {
			const data = await fetch("/addListName");
			try {
				const dataResponse = (await data.json()) as listResponse[];
				setUserList(dataResponse);
			} catch (error) {}
		};
		getListName();
		setLoading(false);
		// setUserList([
		// 	{ name: "biedra", id: "111" },
		// 	{ name: "biedra 2", id: "222" },
		// ]);
	}, [refresh]);

	const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		setShowError(false);
		try {
			const newData = await fetch("/AddListName", {
				method: "put",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ name: data }),
			});
			if (newData.status !== 201) {
				setShowError(true);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setData("");
			setLoading(false);
			setRefresh(!refresh);
		}
	};
	return (
		<>
			<div className="containerHome">
				<form action="" onSubmit={(e) => submitHandler(e)} className="formNewKategory">
					<label htmlFor="newKategory">Nadaj nazwę liście</label>
					<input
						type="text"
						name="newKategory"
						id="newKategory"
						placeholder="...nazwa ..."
						value={data}
						onChange={(e) => {
							if (showError === true) {
								setShowError(false);
							}

							const err = userList?.filter((item) => {
								const a = item.name.toLowerCase();
								const b = e.target.value.toLowerCase();
								return a === b;
							});
							if (err?.length !== 0) {
								setShowError(true);
							} else {
								setShowError(false);
							}

							setData(e.target.value);
						}}
					/>
					<button type="submit" disabled={!showError && data.length > 1 ? false : true}>
						{" "}
						Dodaj
					</button>
				</form>
				{showError && (
					<div className="error">
						<h5>Podana nazwa listy - {data} - już istnieje</h5>
					</div>
				)}
			</div>
			<div className="line"></div>

			<div className="userList">
				{userList?.map((e) => {
					return (
						<>
							<SingleListItem key={e.id} name={e.name} id={e.id} />
						</>
					);
				})}
			</div>
		</>
	);
};
