import { CSSProperties } from "react";
import { SpinnerDotted } from "spinners-react";
import "./spiner.css";

const style: CSSProperties = {
	position: "absolute",
	left: "50%",
	top: "50%",
	transform: "translate(-50%,-50%)",
	color: "black",
	opacity: 1,
};

export const Spinner = () => {
	return (
		<>
			<div className="spinner">
				Loading ... wait
				<SpinnerDotted size={150} style={style} speed={100} aria-label="Loading Spinner" />
			</div>
		</>
	);
};
